import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product } from '@ttstr/api/products';
import {
  Container,
  NativeSlider,
  VariantChooser,
  useShopConfig,
  ContentrArea,
  useProductDetailLink,
} from '@ttstr/components';
import AbsoluteRedirect from '@ttstr/components/Routing/AbsoluteRedirect';
import { isAbsoluteURL } from '@ttstr/utils';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  const thumbs = React.useMemo(
    () => [product.hero_image.thumb.url, ...product.gallery_image_urls.map((i) => i.thumb)],
    [product]
  );

  if (product.redirect) {
    return isAbsoluteURL(product.redirect) ? (
      <AbsoluteRedirect to={product.redirect} />
    ) : (
      <Redirect to={product.redirect} />
    );
  }

  return (
    <article className="product-detail" itemScope itemType="http://schema.org/Product">
      <Helmet>
        <title>
          {product.supertitle && product.supertitle + ' - '}
          {product.title}
          {product.subtitle && ' - ' + product.subtitle}
        </title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} />
        <body className="product-detail-page" />
      </Helmet>
      <meta itemProp="sku" content={String(product.id)} />

      <section className="product-content">
        <Container className="detail-page">
          <Row>
            <Col lg="8">
              <NativeSlider
                images={images.length ? images : [product.hero_image.url]}
                thumbs={images.length ? images : [product.hero_image.url]}
              />
            </Col>
            <Col lg="4">
              <header className="text-center text-lg-left">
                {product.supertitle && <span className="super-title text-muted">{product.supertitle}</span>}
                <h1 className="product-header-title">
                  <span itemProp="name">{product.title}</span>
                  <small className="sub-title">{product.subtitle}</small>
                </h1>
              </header>
              <ContentrArea id="before-variants" />

              <VariantChooser className="mb-4" product={product} showFormerPrice />

              <small className="text-muted">Inkl. MwSt. Versand wird beim Checkout berechnet.</small>

              {product.description && (
                <>
                  <h2 className="product-title mt-5">{t(`PRODUCT.INFORMATION`)}</h2>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
