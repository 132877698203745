import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, useIntl, Masonry, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  /**
   * ticketFilter can be used if you want a Route to a dedicated page for special tickets.
   *
   * Example:
   * You want a page only with tickets that has the tag id 1234.
   * The function for ticketFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialtickets" render={()=> <TicketView ticketFilter={p => p.tag_ids.includes(1234)} />} />
   */
  ticketFilter?: (p: Article) => any;
};

const TicketListingTour24: React.FC<Props> = ({ ticketFilter = (t) => t.tag_ids.includes(7358) }) => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="pb-5 ticket-listing mb-5">
      {/* <Container fluid className='ticket-header-image' /> */}
      <Container fluid className="header-image" />
      <Container className="list-page">
        <Helmet>
          <title>{t(`TICKETLISTING.TITLE`)}</title>
        </Helmet>

        <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1>

        <Container className="px-1">
          <Row className="my-3 d-flex justify-items-center justify-content-center listing-buttons-container">
            <Col className="mt-2 ">
              <Link to="/tour24" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100 active">
                {'Tour 2024'}
              </Link>
            </Col>
            <Col className="mt-2 ">
              <Link to="/f4t0ni" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
                {'F4T0Ni'}
              </Link>
            </Col>
            <Col className="mt-2 ">
              <Link to="/festivals24" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
                {'Festivals 24'}
              </Link>
            </Col>
          </Row>
        </Container>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets.filter(ticketFilter)}
            type="list"
            showCity
            showLocation
            showTags
          />
        )}
        <ContentrArea id="after-ticket-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListingTour24);
